import React from 'react'
import { Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  container: {
    margin: 'auto', 
    width: '90%',
    '@media (max-width: 657px)': {
      height: '400px',
    },
    '@media (max-width: 420px)': {
      width: '95%'
    }
  },
  subtitle: {
    width: '75%',
    margin: 'auto',
    padding: '50px',
    pt: '2rem',
    '@media (max-width: 560px)': {
      textAlign: 'center'
    },
  },
}

const HeroBlock01 = ({
  content: { text = [], images, buttons, form, icon },
  reverse
}) => (
  <Box sx={styles.container}>
    <Flex
      sx={{
        alignItems: ['center', null, null, `center`],
        flexDirection: [
          reverse ? `column` : `column-reverse`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >

      <Box
        sx={{
          margin: 'auto',
          textAlign: [`center`, null, null, `center`]
        }}
      >
        <Reveal effect='fadeInDown'>
          <ContentText sx={styles.subtitle} content={text} />
        </Reveal>

      </Box>
    </Flex>
  </Box>  
)

export default WithDefaultContent(HeroBlock01)
