/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

 import bgFollowUs from '../../../../../site/content/assets/flexiblocks/homepage/follow.svg'

 export default {
   header: {
     bg: '#27A8FF',
     pt: '1rem',
   },
   contact: {
     bg: '#2A205E',
     '@media (max-width: 625px)': {
        paddingBottom: '8rem',
      },
      '@media (max-width: 485px)': {
        paddingBottom: '11rem',
      }
   },
   firstSections: {
    bg: '#FFFFFF',
    pt: '2rem'
  },
  heroOne: {
    bg: '#FFFFFF',
    pt: '6rem',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   sections: {
     bg: '#FFFFFF',
     pt: '9rem',
     '@media (max-width: 991px)': {
      pt: '2rem'
    }
   },
   heroTwo: {
    bg: '#FFFFFF',
    pt: '9rem',
    paddingBottom: '100px',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   sectionSuite: {
    bg: '#F7F8FC',
    pt: '4rem',
    pb: '4rem',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   heroThree: {
    bg: '#FFFFFF',
    pt: '9rem',
    pb: '4rem',
    '@media (max-width: 991px)': {
     pt: '2rem'
   }
  },
   whyChooseUs: {
     bg: '#FFFFFF',
     pt: '2rem',
     pb: '5rem'
   },
   follow : {
    bg: '#F7F8FC',
    backgroundImage: `url(${bgFollowUs})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: 'cover',
    '@media (max-width: 765px)': {
      backgroundSize: 'cover',
      pb: '3rem'
    }
   },
   features: {
     bg: '#FFFFFF',
     pt: '1rem',
     paddingBottom: '5rem'
   },
   testimonials: {
     bg: '#FFFFFF',
     pt: '8rem'
   },
   trustUs: {
     bg: '#FFFFFF',
     pt: '4rem',
     '@media (max-width: 1005px)': {
        pb: '4rem',
     },
     '@media (max-width: 638px)': {
      pb: '10rem',
   },
   },
   pictures: {
    pb: '3rem'
  },
   title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '50px',
    color: '#2A205E',
    textAlign: 'center',
    pt: '1rem',
    paddingBottom: '2rem',
    '@media (max-width: 765px)': {
      fontSize: 30,
      textAlign: 'center',
      padding: '5px'
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  subTitle: {
    width: '80%',
    margin: 'auto',
    fontSize: '18px',
    '@media (max-width: 560px)': {
      textAlign: 'center'
    },
  }
 }