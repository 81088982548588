import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import { Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import Contact from '@solid-ui-blocks/Politicas/Contact'
import favicon from '../../../../../site/content/assets/favicon.png'

import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'
import Features from '@solid-ui-blocks/Politicas/Features'


const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />

      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>

      <Header content={content['header']} />
      
      <Box sx={styles.sectionSuite}>
        <Features content={content['itemsPoliticas']} />
      </Box>

      <Box sx={styles.contact}>
        <Contact content={content['footerPoliticas']} reverse />
      </Box>

      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query empresasPoliticasBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
